import { Controller } from "stimulus"

const truefalseoptions = ['true','false','unknown']
var usepulldown = []
var productclaimpulldown = []
var productusepulldown = []
var productformpulldown = []
var pulldowns = {
  "package_component": [ "", "length_inches", "width_inches", "height_inches", "component_type", "is_bio_based", "is_compostable", "is_curbside_recycle", "is_fsc_paperboard", "is_food_contact_safe", "is_leach_migration_tested", "is_lightweight_candidate", "is_mono_material", "is_non_profit_certified", "is_refillable", "is_regulatory_approved", "is_reusable", "is_upcycled_material", "is_usa_made", "material_alumimum", "material_glass_not_colored", "material_glass_colored", "material_hdpe", "material_metal", "material_paper", "material_pcr", "material_pe", "material_pet", 
    "material_pete", "material_pir", "material_pp", "material_tin", "not_derived_from_palm", "percent_pcr", "percent_pir",  "percent_pcr_plus_pir", "certified_cosmos_organic", "is_biodegradable", "is_credo_packaging_compliant", "is_designed_for_circularity", "is_drop_off_recyclable", "certified_non_gmo_project", "weight", "weight_units", "volume", "volume_units", "material_stainless_steel", "material_ldpe", "material_pla", "material_ps", "material_pvc", "material_abs", "material_mixed_material", "material_ceramic", "material_fiber", "material_plant_based_plastic", "material_wood", "material_silicone", 
    "material_petg", 'material_san', 'material_evoh', 'material_pmma', 
    'ink_vegetable_based', 'ink_water_based', 'ink_uv_based', 'ink_petroleum_based', 'ink_voc',
    'sfg_category_id', 'sfg_sub_category_id',
    'material_group_curbside', 'material_group_dropoff', 'material_group_plastic', 'material_group_metal', 'material_group_biobased', 'material_group_other', 'meets_policy_ids', "-unassessable-" ],
  "chemical": [ "", "name", "cas", "ec_number", "molecular_formula", "smiles", "name_inci", "name_iupac", "-unassessable-" ],
  "material": [ "", "-unassessable-" ],
  "ingredient": [ "", "inhalable", "product_use_ids", "product_claim_ids", "product_form_id", "leave_on", "rinse_off", "percentage", "ingredient_name", "ingredient_function_id", "-unassessable-" ],
  "product": [ "", "inhalable", "product_use_ids", "product_claim_ids", "product_form_id", "leave_on", "rinse_off", "product_name", "variant", "-unassessable-" ]
}
export default class extends Controller {

  static targets = [ 'matchValue', "propertySelect", 'matchValueTwo', "propertySelectTwo", "evaluationPoint" ]

  connect() {
    this.setMaterialPulldowns();
    this.setProductPulldowns();
  }

  setProductPulldowns() {
    fetch(`/science_dashboard/product/claims.json`).then((response) => response.json()).then((json) => {
      productclaimpulldown = json.claims;
    });
    fetch(`/science_dashboard/product/uses.json`).then((response) => response.json()).then((json) => {
      productusepulldown = json.uses;
    });
    fetch(`/science_dashboard/product/forms.json`).then((response) => response.json()).then((json) => {
      productformpulldown = json.forms;
    });
  }

  setMaterialPulldowns() {
    const url = `/science_dashboard/document_attributes.json`;
    fetch(url).then((response) => response.json()).then((json) => {
      usepulldown = json.keys;
      pulldowns["material"] = pulldowns["material"].concat(json.keys);
      usepulldown.push("inhalable");
      usepulldown.push("leave_on");
      usepulldown.push("rinse_off");
      this.chooseProperty();
      this.choosePropertyTwo();
    });
  }

  chooseEvaluationPoint() {
    this.propertySelectTargets.forEach(t => { 
      this.setPropertyPulldown(t)
    });
    this.propertySelectTwoTargets.forEach(t => { 
      this.setPropertyPulldown(t)
    });
  }

  setPropertyPulldown(t, cvalue = null) {
    t.innerHTML = '';
    pulldowns[this.evaluationPointTarget.value].forEach(item => {
      const opt = document.createElement('option');
      opt.value = item;
      opt.innerHTML = item;
      opt.selected = (cvalue === item);
      t.appendChild(opt);
    });
  }

  choosePropertyTwo() {
    var inputNodes = this.matchValueTwoTarget.getElementsByTagName('INPUT');
    var selectNodes = this.matchValueTwoTarget.getElementsByTagName('SELECT');
    if(inputNodes && usepulldown.includes(this.propertySelectTwoTarget.value)) {
      this.makePulldown(inputNodes[0], truefalseoptions);
    } else if(selectNodes && !usepulldown.includes(this.propertySelectTwoTarget.value)) {
      this.makeInput(selectNodes[0]);
    }
  }


  chooseProperty() {
    var inputNodes = this.matchValueTarget.getElementsByTagName('INPUT');
    var selectNodes = this.matchValueTarget.getElementsByTagName('SELECT');
    if(inputNodes[0] && usepulldown.includes(this.propertySelectTarget.value)) {
      this.makePulldown(inputNodes[0], truefalseoptions);
    } else if(selectNodes[0] && !usepulldown.includes(this.propertySelectTarget.value)) {
      this.makeInput(selectNodes[0]);
    }
  }

  makeInput(selectNode) {
    var inputElement = document.createElement('input');
    inputElement.type = "text";
    inputElement.id = selectNode.id
    inputElement.name = selectNode.name
    inputElement.value = selectNode.value
    selectNode.parentElement.replaceChild(inputElement, selectNode);
  }

  makePulldown(inputNode, optionstouse) {
    var selectElement = document.createElement('select');
    selectElement.id = inputNode.id
    selectElement.name = inputNode.name
    var cvalue = inputNode.value
    optionstouse.forEach(item => {
      const opt = document.createElement('option');
      opt.value = item;
      opt.innerHTML = item;
      opt.selected = (cvalue === item);
      selectElement.appendChild(opt);
    });
    inputNode.parentElement.replaceChild(selectElement, inputNode);
  }

}


