import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ 'attributeId', 'valueLow', 'valueMax' ]

  connect() {
    this.chooseValueType();
  }

  chooseValueType() {
    var inputNode = this.valueLowTarget.getElementsByTagName('INPUT')[0] || this.valueLowTarget.getElementsByTagName('SELECT')[0];
    this.valueMaxTarget.hidden = true;
    const url = `/science_dashboard/document_attributes/${this.attributeIdTarget.value}.json`;
    fetch(url).then((response) => response.json()).then((json) => {
        if (json.value_type=='true_false_unknown') {
          this.makePulldown(inputNode, ['true','false','unknown']);
        } else if (json.value_type=='string') {
          this.makeInput(inputNode);
        } else if (json.value_type=='numeric') {
          this.makeInput(inputNode);
          this.valueMaxTarget.hidden = false;
        } else if (json.value_type=='options') {
          this.makePulldown(inputNode, json.acceptable_values.split(','));
        } 
      });
  }

  makeInput(selectNode) {
    var inputElement = document.createElement('input');
    inputElement.type = "text";
    inputElement.id = selectNode.id
    inputElement.name = selectNode.name
    inputElement.value = selectNode.value
    selectNode.parentElement.replaceChild(inputElement, selectNode);
  }

  makePulldown(inputNode, optionstouse) {
    var selectElement = document.createElement('select');
    var cvalue = inputNode.value
    selectElement.id = inputNode.id
    selectElement.name = inputNode.name
    optionstouse.forEach(item => {
      const opt = document.createElement('option');
      opt.value = item;
      opt.innerHTML = item;
      opt.selected = (cvalue === item);
      selectElement.appendChild(opt);
    });
    inputNode.parentElement.replaceChild(selectElement, inputNode);
  }

}


