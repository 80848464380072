import { Controller } from "stimulus"


export default class extends Controller {

  static targets = [ 
      'tabHeader', 'tabContent'
    ]

  connect() {
  }
  
  selectTab(e) {
    var targetTab = e.currentTarget.dataset.tabToOpen;
    this.pickTab(targetTab);
  }

  pickTab(targetTab) {
    this.tabContentTargets.forEach(t => { 
      if(t.dataset.tabName === targetTab) {
        t.classList.remove("hidden");
      } else {
        t.classList.add("hidden");
      }
    });
    this.tabHeaderTargets.forEach(t => { 
      if(t.dataset.tabToOpen === targetTab) {
        t.classList.add("active-tab");
      } else {
        t.classList.remove("active-tab");
      }
    });
  }

}


