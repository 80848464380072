import { Controller } from "stimulus"


export default class extends Controller {

  static targets = [ "parent", "fullScreen", "menuList" ]

  connect() {
  }
  openCloseMenu() {
    this.fullScreenTarget.classList.toggle("hidden");
    this.menuListTarget.classList.toggle("hidden");
  }

}


