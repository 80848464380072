// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import "bootstrap";


import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// import Popper from "popper"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "stylesheets/application";
import "stylesheets/global";
import "cocoon-js-vanilla";



// window.$ = window.jQuery = $;
// window.Rails = Rails;
// window.Turbolinks = Turbolinks;
// window.Popper = Popper;
// global.Popper = Popper;

import "controllers"
