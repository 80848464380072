import { Controller } from "stimulus"


export default class extends Controller {

  static targets = ['checkboxAll', 'checkbox']

  selectAll() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = true;
    })
  }

  deselectAll() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false;
    })
  }

}


