import { Controller } from "stimulus"


export default class extends Controller {

  static targets = [ "fulldiv" ]

  removeParent() {
    this.fulldivTarget.classList.add("hidden");
  }

}


